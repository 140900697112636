import Sizes from './sizes'
import throttle from 'lodash.throttle'

export default class Navbar {
  constructor() {
    this.navMini = document.querySelector('#toggle-mini')
    this.sticky = document.querySelector('#is-mobile-sticky')
    if (!this.navMini && !this.sticky) return

    this.initToggle()
  }

  initToggle() {
    const checkScroll = () => {
      const pageY = window.scrollY

      this.navMini.classList.toggle('is-active', pageY > 200)
    }

    const checkSticky = () => {
      const pageY = window.scrollY
      this.sticky.classList.toggle('is-active', pageY > Sizes.window.height)
    }

    if (Sizes.isDesktop) {
      window.addEventListener('scroll', throttle(checkScroll, 100))
    } else {
      if (this.sticky)
        window.addEventListener('scroll', throttle(checkSticky, 100))
    }
  }
}
