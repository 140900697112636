export default class ScrollLinks {
  constructor() {
    document.addEventListener('click', (e) => {
      if (e.target.dataset.scroll) {
        e.preventDefault()
        const element = document.querySelector('#' + e.target.dataset.scroll)
        if (element) element.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }
}
