import API from '@/js/api'
// import throttle from 'lodash.throttle'

export default class Search {
  constructor() {
    this.container = document.querySelector('#search-container')
    if (!this.container) return

    this.lang = document.documentElement.lang

    this.counter = this.container.querySelector('.js-counter')
    this.form = this.container.querySelector('form')
    this.btnClear = this.container.querySelector('.js-clear')
    this.btnSearch = this.container.querySelector('.js-search')
    this.results = document.querySelector('#search-results')
    this.field = this.container.querySelector('.field')
    this.input = this.container.querySelector('input')
    this.input.value = new URLSearchParams(window.location.search).get('query')
    this.search()

    this.form.addEventListener('submit', (e) => {
      e.preventDefault()
      this.search()
    })

    this.form.addEventListener('reset', (e) => {
      e.preventDefault()
      this.input.value = ''
      this.input.focus()
      this.search()
    })
  }

  search() {
    if (this.input.value === this.query) return

    this.query = this.input.value
    this.field.classList.toggle('is-primary', this.query.length)
    this.btnClear.classList.toggle('is-hidden', !this.query.length)
    this.btnSearch.classList.toggle('is-hidden', this.query.length)

    window.history.replaceState(
      {},
      '',
      '?' + new URLSearchParams({ query: this.query })
    )

    this.btnClear.classList.add('is-loading')
    this.btnSearch.classList.add('is-loading')
    this.results.innerHTML = ''

    return API.post('/' + this.lang + '/search/?query=' + this.input.value)
      .then((response) => {
        this.results.innerHTML = response.html
        this.counter.parentNode.classList.toggle('is-hidden', !response.count)

        if (response.count) {
          this.counter.innerHTML = response.count
        }
      })
      .finally(() => {
        this.btnClear.classList.remove('is-loading')
        this.btnSearch.classList.remove('is-loading')
      })
  }
}
