import Carousel from './carousel'
import ConsentCookies from './consent-cookies'
import DialogConfirm from './dialog-confirm'
import Favs from './favs'
import MediaGrid from './media-grid'
import Modals from './modals'
import Navbar from './navbar'
import ScrollLinks from './scroll-links'
import Search from './search'

import '../scss/base.scss'

class CAPP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      console.log('[CC+ base online]')

      const modals = new Modals()
      const navbar = new Navbar()
      const favs = new Favs()
      const carousel = new Carousel('.carousel.is-default', {
        prevNextButtons: false,
        pageDots: false,
        setGallerySize: false,
        wrapAround: true,
      })

      const carouselBox = new Carousel('.carousel.is-boxed', {
        prevNextButtons: false,
        pageDots: false,
        setGallerySize: true,
        wrapAround: false,
        contain: true,
        groupCells: true,
      })

      const dialog = new DialogConfirm()
      const scrollLinks = new ScrollLinks()
      const media = new MediaGrid()
      const search = new Search()
    })
  }
}

const APP = new CAPP()
export default APP

window.consentCookies = new ConsentCookies()
