import API from './api'

export default class Favs {
  constructor() {
    document.addEventListener('click', (event) => {
      const element = event.target

      if (
        element.classList.contains('is-fav') &&
        !element.classList.contains('is-inactive')
      ) {
        element.classList.add('is-loading')

        API.toggleFav(element.dataset.fav, element.dataset.id)
          .then(() => {
            if (element.dataset.fav) {
              element.removeAttribute('data-fav')
              element.classList.add('is-black')
              element.classList.remove('is-primary')
            } else {
              element.setAttribute('data-fav', 'true')
              element.classList.add('is-primary')
              element.classList.remove('is-black')
            }
          })
          .finally(() => {
            element.classList.remove('is-loading')
          })
      }
    })
  }
}
