export default class DialogConfirm {
  constructor() {
    document.addEventListener('click', (event) => {
      if (event.target.dataset.confirm) {
        event.preventDefault()

        if (window.confirm(event.target.dataset.confirm)) {
          window.location.href = event.target.href
        }
      }
    })
  }
}
