import Flickity from 'flickity'

export default class Carousel {
  constructor(selector, options, onReady) {
    this.elements = document.querySelectorAll(selector)
    if (!this.elements.length) return

    this.instances = []

    this.elements.forEach((element, i) => {
      const container = element.querySelector('.carousel-container')
      const btnPrev = element.querySelector('.is-prev')
      const btnNext = element.querySelector('.is-next')
      const opts = { ...options }

      if (element.classList.contains('has-captions')) {
        const captions = container.querySelectorAll('.is-counter')
        const ohCaption = element.querySelector('.oh-caption .is-counter')

        opts.on = Object.assign({}, options.on, {
          change: (index) => {
            ohCaption.innerHTML = captions[index].innerHTML.trim()

            container.focus({
              preventScroll: true,
            })
          },

          ready: () => {
            ohCaption.innerHTML = captions[0].innerHTML.trim()
          },
        })
      }

      requestAnimationFrame(() => {
        element.classList.remove('is-hidden')
        this.instances[i] = new Flickity(container, opts)

        if (onReady) onReady()
      })

      if (btnPrev) {
        btnPrev.addEventListener('click', () => {
          this.instances[i].previous()
        })
      }

      if (btnNext)
        btnNext.addEventListener('click', () => {
          this.instances[i].next()
        })
    })
  }
}
